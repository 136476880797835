import {useEffect, useState} from "react";

function useScrollToTop(){

    const [loaded, setLoaded] = useState(false);
    useEffect(()=>{
        if(!loaded){
            window.scrollTo(0,0);
            setLoaded(true)
        }
    }, [loaded])

}

export default useScrollToTop