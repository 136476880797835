import React from "react";

function Philo(){
    return(
        <>
            <div className="mw-600px mhor-auto center t-white">
                <h3 className="mb">
                    Our Philsophy
                </h3>
                <p className="phor-3 f-medium lh-150 mobile:f-1rem">
                    We’ve carefully crafted our curriculum based on cutting edge research on empathy, pedagogy, and brain science, and based it off of our subsequent theory of change. That theory of change is based on an expansive view of learning, asking the learner to first connect with themselves, and then move through the following progressive benchmarks of growth.
                </p>
            </div>
	
					
					<div className="grid-cur align-start">
						<div className="p-2 br green mb-3 mt-2 mobile:mb-2 delete-mob-margin">
							<div className="center">
								<img className="w-15rem h-auto" src={require("../css/deedly/week1.png")}/>
							</div>
							<h4 className="mv">Week 1: Self</h4>
							<p className="lh-150">
								All understanding is set up for growth if it starts with a healthy curiosity and exploration of oneself, our bodies and minds. Learning experiences start from developing a practice of positive self- reflection and identification with one's own uniqueness and experiences.
							</p>
						</div>
						
						<div>
							<img className="w-8rem h-auto mobile:hidden" src={require("../css/deedly/arrow-curriculum.png")}/>
							<div className="p-2 br red mb-3 mt mobile:mb-2 delete-mob-margin">
								<div className="center">
									<img className="w-15rem h-auto" src={require("../css/deedly/week2.png")}/>
								</div>
								<h4 className="mv">Week 2: Community</h4>
								<p className="lh-150">
									As our individual knowledge and curiosity of ourselves expands, we see an increase in our ability to make connections with those we love and interact with regularly are complex beings - our community. We start to take notice of what we have common with humans near and far. Practicing moving from an understanding of the self to the understanding of others around us scaffolds that expansive pattern for increasing empathic capacities.
								</p>
							</div>
						</div>
						
						<div className="p-2 br blue mver-3 mobile:mb-2 adjust-w3 delete-mob-margin">
							<div className="center">
								<img className="w-15rem h-auto" src={require("../css/deedly/week3.png")}/>
							</div>
							<h4 className="mv">Week 3: Earth</h4>
							<p className="lh-150">
								Our expanding empathy continues towards our largest sphere, the entire population of earthlings. We see that we share this planet earth with all sorts of life, and that the earth is an ecosystem we depend on every day in order to thrive. During this week we move from things that are easier to relate to, to understanding and showing respect to things we care about but don’t immediately always seem relatable.
							</p>
						</div>
						
						<div className="p-2 br yellow mver-3 mobile:mb-2 adjust-w4 delete-mob-margin">
							<div className="center">
								<img className="w-15rem h-auto" src={require("../css/deedly/week4.png")}/>
							</div>
							<h4 className="mv">Week 4: Stories</h4>
							<p className="lh-150">
								All the facts in the world will not change hearts and minds. A well-told story can. Our connections come to life through the stories we tell, which make it easier to identify the connections between all of us, generating empathy towards living creatures far and near. That empathy then extends one's moral imagination to give our existence meaning beyond just physical matter, and allows us access to motivation outside of ourselves. Stories inspire and spark empathic action, and connect and ground all new learned concepts, thoughts and feelings.
							</p>
						</div>
					</div>
					
					
        </>
    )
}

export default Philo
