import React, {useEffect, useState} from "react";
import useScrollToTop from "../components/hooks/useScrollToTop";
import useContentful from "../components/hooks/useContentful";

import Philo from "../components/Philosophy";
import Cur from "../components/Curriculum";

function Page() {

    const [mounted, setMounted] = useState(false);
    useEffect(()=>{
        if (!mounted) {
            window.scrollTo(0,0);
            setMounted(true);
        }
    },[mounted]);

    const [tab, setTab] = useState("philosophy");

    const handleTab = (val)=>{
        setTab(val);
    }

    useScrollToTop();

    const {isLoading, isError, loaded, data} = useContentful({content_type : 'curriculum', 'order' : 'fields.title'}, 'getEntries');


    return(
        <div className="spaceblue grows">
            <div className="mw-1360px mhor-auto relative">
                <div className="absolute left-0 top-1-n bottom-0 w-15rem bg-contain bg-no-repeat bg-top mobile:hidden" style={{backgroundImage : `url(${require("../css/deedly/star-scorpio.png")})`}}>
                </div>
                <div className="absolute right-0 top-1-n bottom-0 w-15rem bg-contain bg-no-repeat bg-top mobile:hidden top-10" style={{backgroundImage : `url(${require("../css/deedly/star-gemini.png")})`}} >
                </div>
                <div className="mw-1056px mhor-auto mt-2">

                    <h1 className="mb center t-white mb mobile:h2">Our Curriculum</h1>

                    <div className="p t-white f-medium flex justify-center mb-2 mobile:f-1rem">
                        <a className={`mhor mobile:mhor-half inline-flex pb b-bottom-5 ${tab === "philosophy" ? "b-red t-red" : "b-spaceblue hover:t-grey-light"}`} onClick={()=>{handleTab("philosophy")}}>Our Philosophy</a>

                        {data.items && data.items.map( ({fields, sys}, index) =>{
                            return (
                                <a className={`mhor mobile:mhor-half inline-flex pb b-bottom-5 ${tab === sys.id ? "b-red t-red" : "b-spaceblue hover:t-grey-light"}`} key={sys.id}  onClick={()=>{handleTab(sys.id)}}>{fields.title}</a>
                            )
                        })}

                    </div>

                    {tab === "philosophy" ?

                        <Philo />

                        :

                        <Cur id={tab} />

                    }

                </div>
            </div>
        </div>
    )
}

export default Page
