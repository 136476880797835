import React,{useState,useEffect,useReducer} from "react";
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import {renderToStaticMarkup} from "react-dom/server";


function contentfulToHtmlString(htmlObj){

    const options={
        renderNode:{
            "embedded-asset-block":(node)=>{
                let file=node.data.target.fields.file;
                let jsx=renderMedia(file);
                let markup=renderToStaticMarkup(jsx);
                return markup;
            }
        }
    };


    const renderMedia=(file)=>{
        if(file.contentType==="video/mp4"){
            return (
                <div className='adjust-video'>
                    <video controls>
                        <source src={file.url} type='video/mp4'/>
                        <p>Your browser doesnt support HTML5 video.</p>
                    </video>
                </div>
            );
        }else if(file.contentType==="image/jpeg" || file.contentType==="image/png" || file.contentType==="image/gif"){
            // console.log("");
            return (<img className="img-full-fluid total-display" src={file.url}/>);
        }else{
            return (<p>Unknown content type</p>);
        }
    };

    return (documentToHtmlString(htmlObj,options));

}


export default contentfulToHtmlString;