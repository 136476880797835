import React, {useEffect, useState} from "react";
import HexMask from "../components/HexMask";

const pillStyle = 't-spaceblue inline-flex align-center phor br h-2rem mr-half';

function Pill(props) {

    const {donation,val,handleDonation, customDonation} = props;

    return(
        <a className={`mobile:mb ${donation===val && !customDonation ? "green" : "grey-light"} ${pillStyle}`} onClick={()=>{handleDonation(val, true)}}>${val}</a>
    )

}

function Page() {

    const [mounted, setMounted] = useState(false);
    useEffect(()=>{
        if (!mounted) {
            window.scrollTo(0,0);
            setMounted(true);
        }
    },[mounted]);


    const [donateOptions] = useState([5,10,25,50,100]);
    const [donation, setDonation] = useState(25);
    const [customDonation, setCustomDonation] = useState(false)
    const [monthly, setMonthly] = useState(false)

    const [step, setStep] = useState("option");

    function changeStep(vel){

        console.log("Clicked button", vel);
        if(step==="card" && vel==="option"){
            setStep("option")
        }
        else if(step==="option"){
            setStep("card")
        }
        else if(step==="card" && !vel){
            alert("Plase add a valid Credit Card")
        }

        /*console.log("Changin Step", vel);
        const v = vel;
        console.log("V", v)

        if(v){
            console.log("there was vel")
            setStep(v);
        }
        else {
            switch(step) {
                case "option":
                    setStep("card")
                    break;
                default:
                // code block
            }
        }*/

    }

    const handleDonation = (val, pill)=> {
        console.log("Handling Click", val)
        setDonation(val);
        pill ? setCustomDonation(false) : setCustomDonation(true)

    }

    const handleCustom = ()=> {
        setCustomDonation(true);
    }

    const handleMonthly = ()=> {
        console.log("Handling Monthly")
        setMonthly(!monthly);
    }

    useEffect(()=>{
        if(customDonation) {
            const input = document.getElementById("custom-donation");
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
    },[customDonation])


    return(
        <div className="spaceblue grows">

            <div className="mw-1360px relative mhor-auto">

                <div className="absolute left-0 top-1-n bottom-0 w-15rem bg-contain bg-no-repeat bg-top mobile:hidden" style={{backgroundImage : `url(${require("../css/deedly/star-scorpio.png")})`}}>
                </div>
                <div className="absolute right-0 top-1-n bottom-0 w-15rem bg-contain bg-no-repeat bg-top mobile:hidden top-10" style={{backgroundImage : `url(${require("../css/deedly/star-gemini.png")})`}} >
                </div>

                <div className="mw-600px relative mhor-auto mobile:phor">
                    <div className="t-white center pt-3 pb-2">
                        <h1 className="mb relative mobile:h2">Support Deedly</h1>
                        <p className="f-medium lh-150 pr-3 mobile:pr-0">
                            Donate today, support students, and spark a ripple effect of positive change today — and in the future.
                        </p>
                    </div>
                </div>
                <div className="mw-600px relative mhor-auto mobile:phor">

                    <div className="p-2 white br mw-600px mhor-auto relative z-100">

                        {step=="option" &&
                        <div>
                            <h2 className="center mobile:h3">Make a Donation</h2>
                            <div className="pver flex wrap justify-center">
                                {donateOptions.map(
                                    (value)=>{
                                        return(
                                            <Pill key={value} val={value} donation={donation} customDonation={customDonation} handleDonation={handleDonation} />
                                        )
                                    }
                                )}
                                <a className={`${customDonation ? "green" : "grey-light"} ${pillStyle}`} onClick={handleCustom}>Other</a>
                            </div>
                            {customDonation ?
                                <div className="center pver">
                                    <input type="text" id="custom-donation" value={donation} placeholder="Enter $ amount" className="b-grey-light phor h-3rem w-100 mw-200px b f-medium center br-half" onChange={(e)=>{handleDonation(parseInt(e.target.value), false)}}/>
                                </div>
                            :
                            null
                            }

                            <div className="pver flex justify-center align-center f-medium">
                                One time
                                <div className={`w-5rem h-3rem br-1half mhor-half relative ${monthly ? "green-dark" : "green"}`} onClick={handleMonthly}>
                                    <div className={`sq-2halfrem br-1half white m-quarter absolute ${monthly ? "right-0" : "left-0"}`}></div>
                                </div>
                                Monthly
                            </div>
                        </div>
                        }

                        {step=="card" &&
                        <div>
                            <h2 className="center mobile:h3">Donating ${donation}</h2>
                            <p className="center">
                                <a className="t-blue" onClick={()=>{ changeStep("option")}}>Edit donation</a>
                            </p>
                            <form>
                                <p className="f-medium t-grey mver">
                                    Credit Card
                                </p>
                                <input type="text" className="h-3rem b b-grey-light p w-100 br-half f-medium mb" placeholder="Name on Card" />
                                <input type="text" className="h-3rem b b-grey-light p w-100 br-half f-medium mb" placeholder="Email" />
                                <div className="grid3 col-gap">
                                    <div className="col-span2">
                                        <input type="text" className="h-3rem b b-grey-light p w-100 br-half f-medium mb" placeholder="Card Number" />
                                    </div>
                                    <div className="grid2 col-gap">
                                        <input type="text" className="h-3rem b b-grey-light p w-100 br-half f-medium mb" placeholder="MM" />
                                        <input type="text" className="h-3rem b b-grey-light p w-100 br-half f-medium mb" placeholder="YY" />
                                    </div>
                                </div>
                                <div className="grid3 col-gap">

                                    <div>
                                        <input type="text" className="h-3rem b b-grey-light p w-100 br-half f-medium mb" placeholder="CCV" />
                                    </div>
                                    <div className="col-span2">
                                        <input type="text" className="h-3rem b b-grey-light p w-100 br-half f-medium mb" placeholder="Zip Code" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        }

                        <div className="center pver">
                            <a className="button teal t-white br-2 w-100 button-large" onClick={()=>{changeStep("")}}>Donate ${donation}</a>
                        </div>

                    </div>

                    <div className="w-15rem absolute left-14-n top-0 mobile:hidden">
                        <HexMask image={require("../css/deedly/donate1.jpg")} id="donate1" />
                    </div>

                    <div className="w-6rem absolute z-10 left-14-n top-16 mobile:hidden">
                        <HexMask color="green-dark" />
                    </div>



                    <div className="w-8rem absolute right-16-n top-6-n mobile:hidden">
                        <HexMask color="green-dark" />
                    </div>



                    <div className="w-400px absolute right-20-n top-2 mobile:hidden">
                        <HexMask image={require("../css/deedly/donate2.jpg")} id="donate2" />
                    </div>

                </div>

            </div>

            <div className="spaceblue-dark pver-4 mt-6 t-white pver-8 mobile:mt-2 mobile:pt-4 mobile:pb-2">
                <div className="mw-1056px mhor-auto grid2 mobile:block mobile:phor-2">
                    <div>
                        <h2 className="mobile:h3">Why Deedly?</h2>
                        <p className="f-large lh-150 mobile:f-medium mobile:mb-2">
                            Deedly is a fantastic way not only to support the charities you love, but to support new, young activists to get involved with them, too — so your donation doubles its impact!
                        </p>
                    </div>
                    <div>
                        <img src={require('../css/deedly/why-deedly.png')} className="w-100" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Page
