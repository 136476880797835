import React,{useState,useEffect,useReducer} from "react";
import {createClient} from "contentful";

const client=createClient({
	space:"ltnak4n9hwfw",
	accessToken:"AiKmFU3EbSp3vZLKMzH_z2dyBfBgSKg0OD2jva1tMHE"
});


function stateReducer(state,action){
	
	switch(action.type){
		case "LOADING":
			return {
				...state,
				isLoading:true,
				isError:false
			};
		case "LOADED":
			return {
				...state,
				loaded:true,
				isLoading:false,
				isError:false,
				data:{...action.data}
			};
		case "ERROR":
			return {
				...state,
				loaded:true,
				isLoading:false,
				isError:true
			};
	}
}


const useContentful=(q,m)=>{
	
	const [contentfulParams,setContentfulParams]=useState({query:q,method:m});
	
	const [state,dispatch]=useReducer(stateReducer,{
		isLoading:false,
		isError:false,
		loaded:false,
		data:{}
	});
	
	useEffect(()=>{

		console.log("Getting", contentfulParams.method, contentfulParams.query)
		
		const fetchContentful=async()=>{
			dispatch({type:"LOADING"});
			try{
				if(contentfulParams.method==="getEntry"){
					const result=await client[contentfulParams.method](contentfulParams.query["sys.id"]);
					dispatch({type:"LOADED",data:result});

				}else{
					const result=await client[contentfulParams.method]({...contentfulParams.query});
					dispatch({type:"LOADED",data:result});
				}
				
				
			}catch(e){
				dispatch({type:"ERROR"});
			}
			
		};
		
		fetchContentful();
		
	},[contentfulParams]);
	
	return ({...state});
	
};


export default useContentful;