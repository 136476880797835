import React, {useState} from "react";

function HexMask(props) {

    const {image, id} = props;

    return(
        /*<svg viewBox="0 0 131 149" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1">
            <path id="deedo" d="M59.5 2.4641C63.2128 0.320506 67.7872 0.320508 71.5 2.4641L124.885 33.2859C128.598 35.4295 130.885 39.391 130.885 43.6782V105.322C130.885 109.609 128.598 113.571 124.885 115.714L71.5 146.536C67.7872 148.679 63.2128 148.679 59.5 146.536L6.11508 115.714C2.40226 113.571 0.115082 109.609 0.115082 105.322V43.6782C0.115082 39.391 2.40227 35.4295 6.11508 33.2859L59.5 2.4641Z" fill="#579482" />
            <clipPath id="deedo">
                <path id="deedo" d="M59.5 2.4641C63.2128 0.320506 67.7872 0.320508 71.5 2.4641L124.885 33.2859C128.598 35.4295 130.885 39.391 130.885 43.6782V105.322C130.885 109.609 128.598 113.571 124.885 115.714L71.5 146.536C67.7872 148.679 63.2128 148.679 59.5 146.536L6.11508 115.714C2.40226 113.571 0.115082 109.609 0.115082 105.322V43.6782C0.115082 39.391 2.40227 35.4295 6.11508 33.2859L59.5 2.4641Z" fill="#000000" />
            </clipPath>
            {image ?
                <image clipPath="url(#deedo)" height="100%" width="100%" xlinkHref={image}
                       preserveAspectRatio="xMidYMin slice"></image>
                :
                null
            }
        </svg>*/

        <>
            <svg viewBox="0 0 131 149" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <pattern id={id} patternUnits="userSpaceOnUse" width="150" height="170" y="0">
                        <image xlinkHref={image} width="100%" height="100%" preserveAspectRatio="xMinYMin slice" />
                    </pattern>
                </defs>
                <path id="deedo" d="M59.5 2.4641C63.2128 0.320506 67.7872 0.320508 71.5 2.4641L124.885 33.2859C128.598 35.4295 130.885 39.391 130.885 43.6782V105.322C130.885 109.609 128.598 113.571 124.885 115.714L71.5 146.536C67.7872 148.679 63.2128 148.679 59.5 146.536L6.11508 115.714C2.40226 113.571 0.115082 109.609 0.115082 105.322V43.6782C0.115082 39.391 2.40227 35.4295 6.11508 33.2859L59.5 2.4641Z" fill={image ? `url(#${id})` : "#579482"} />
                {/*<polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill={image ? `url(#${id})` : "#579482"}/>*/}
            </svg>
        </>
    )

}

export default HexMask