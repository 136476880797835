import React from "react";
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import {renderToStaticMarkup} from "react-dom/server";


const PopupCharities = ({close, data}) => {
	
	
	const options={
		renderNode:{
			"embedded-asset-block":(node)=>{
				let file=node.data.target.fields.file;
				let jsx=renderMedia(file);
				let markup=renderToStaticMarkup(jsx);
				return markup;
			}
		}
	};
	
	const renderMedia=(file)=>{
		if(file.contentType==="video/mp4"){
			return (
				<div className='adjust-video'>
					<video controls>
						<source src={file.url} type='video/mp4'/>
						<p>Your browser doesnt support HTML5 video.</p>
					</video>
				</div>
			);
		}else if(file.contentType==="image/jpeg" || file.contentType==="image/png" || file.contentType==="image/gif"){
			return (<img className="img-full-fluid total-display" src={file.url}/>);
		}else{
			return (<p>Unknown content type</p>);
		}
	};
	
	
	return (
		<>
			<div className="fixed-pop">
				<div className="close-pop"  onClick={close}></div>
				
				
				<div className="box-popup400">
					<div className="header-pop">
						<div className="pos-close-pop" onClick={close}>
								<img className="icon15" src={require('../css/deedly/icon-close-white.svg')} alt="close"/>
						</div>
						<div className="pos-constellation-pop">
							<img src={require('../css/deedly/gemini-constellation.svg')} alt="gemini-constellation"/>
						</div>
						<p className="color-white font20">Charity</p>
					
					</div>
					<div className="pad20 scroll-mob">
						<div className="grid-row20">
							<div className="box-img-pop">
								<a href={data.fields.charityLink} target="_blank">
									<img src={data.fields.logo.fields.file.url} className="img-full-fluid" alt={data.fields.title} />
								</a>
							</div>
							
							<div className="grid-row10">
								<h2 className="left font25 color-space-blue">{data.fields.title}</h2>
								<div dangerouslySetInnerHTML={{__html:documentToHtmlString(data.fields.description, options)}} className="grid-row20"></div>
							</div>
							
						</div>
		
					</div>
				
				</div>
			
			</div>
		</>
	);
};

export default PopupCharities;
