const colors = [
    {
        name : "green",
        hex : "#67B19A"
    },
    {
        name : "green-dark",
        hex : "#579482"
    },
    {
        name : "green-lighter",
        hex :  "#E1EFEB"
    },
    {
        name : "yellow",
        hex : "#EBC367"
    },
    {
        name : "yellow-dark",
        hex : "#BE9357"
    },
    {
        name : "red",
        hex : "#F38175"
    },
    {
        name : "red-dark",
        hex : "#CA6B64"
    },
    {
        name : "blue-light",
        hex : "#81AFE2"
    },
    {
        name : "blue",
        hex : "#709ED2"
    },
    {
        name : "blue-dark",
        hex : "#5D83AD"
    },
    {
        name : "blue2",
        hex : "#345479"
    },
    {
        name : "blue2-dark",
        hex : "#04334E"
    },
    {
        name : "teal",
        hex : "#217A6A"
    },
    {
        name : "teal-dark",
        hex : "#015F4E"
    },
    {
        name : "grey-light",
        hex : "#E2E2E2"
    },
    {
        name : "spaceblue",
        hex : "#1D3745"
    },
    {
        name : "spaceblue-dark",
        hex : "#0D2938"
    },
    {
        name : "white",
        hex : "#FFFFFF"
    },
    {
        name : "white50",
        hex : "rgba(255,255,255,0.5)"
    },
    {
        name : "white30",
        hex : "rgba(255,255,255,0.3)"
    },
    {
        name : "white20",
        hex : "rgba(255,255,255,0.2)"
    },
    {
        name : "white10",
        hex : "rgba(255,255,255,0.1)"
    },
    ,
    {
        name : "black90",
        hex : "rgba(0,0,0,0.9)"
    },
    {
        name : "black",
        hex : "rgba(255,255,255,0.5)"
    }
];


let style = colors.map(
    ({name, hex})=>{
        return(
            `.t-${name}, .hover\\:t-${name}:hover{ color: ${hex}; }
.${name}, .hover\\:${name}:hover{ background-color: ${hex}; }
.b-${name}, .hover\\:b-${name}:hover, .focus\\:b-${name}:focus{ border-color: ${hex}; }
.sh-4-${name}, .hover\\:sh-4-${name}:hover, .focus\\:sh-4-${name}:focus{box-shadow: 0px 0px 4px ${hex};}`
        )
    }
).join(" \n\n");


function addcss(css){
    var head = document.getElementsByTagName('head')[0];
    var s = document.createElement('style');
    s.setAttribute('type', 'text/css');
    if (s.styleSheet) {   // IE
        s.styleSheet.cssText = css;
    } else {                // the world
        s.appendChild(document.createTextNode(css));
    }
    head.appendChild(s);
}

addcss(style);
