import React, {useEffect, useState} from "react";
import HexMask from "../components/HexMask";
import HowItWorks from "../components/HowItWorks";
import useContentful from "../components/hooks/useContentful";
import PopupTeam from "../components/popupTeam";

function Page() {

    const [mounted, setMounted] = useState(false);
    useEffect(()=>{
        if (!mounted) {
            window.scrollTo(0,0);
            setMounted(true);
        }
    },[mounted]);


    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);


    const {isLoading, isError, loaded, data} = useContentful({content_type : 'team', 'order' : 'fields.order', 'fields.director' : true}, 'getEntries');

    const {isLoading : teamLoading, isError : teamError, loaded : teamLoaded, data : team} = useContentful({content_type : 'team', 'order' : 'fields.order', 'fields.director' : false}, 'getEntries');

    const [popup, setPopup] = useState(false);
    const [infopop, setInfoPop] = useState('');

    const openPopUp=()=> {
        setPopup(!popup)
    }

    const getInfoPop=(pos)=> {
        setPopup(!popup)
        setInfoPop(pos)
        console.log('esta es la info al pop', infopop)
    }

    return(
       <>
           <div className="spaceblue grows">

               <div className="mw-1360px mhor-auto mt-2 red br p-3 mobile:p-2 mobile:mhor">
                   <div className="grid3 mobile:block">
                       <div className="col-span2 t-spaceblue relative">
                           <div className="absolute edges top-1-n bottom-1-n w-300px bg-contain bg-no-repeat bg-center mobile:hidden" style={{'backgroundImage' : `url(${require('../css/deedly/about-stars1.png')})`}}>
                           </div>
                           <h1 className="mb relative mobile:h2">About us</h1>
                           <p className="f-large lh-150 pr-3 mb mobile:f-medium mobile:pr-0">
                               Through innovative technology, captivating content, and evidence-based, standards-aligned curriculum, Deedly takes a holistic approach to educating students by incorporating their social, emotional, civic, cognitive, and aesthetic abilities.
                           </p>
                           <p className="f-large lh-150 pr-3 mb-4 mobile:f-medium mobile:pr-0 mobile:mb-2">
                               We partner with experts who have a proven track record of effecting positive change in strategic areas outlined in our stories.
                           </p>

                           <p className="f-large lh-150 pr-3 mb-4 mobile:f-medium mobile:pr-0 mobile:mb-2">
                               We aim to relieve some of the burden put on teachers, especially as it relates to civic and social-emotional learning.
                           </p>

                           <p className="f-large lh-150 pr-3 mb-4 mobile:f-medium mobile:pr-0 mobile:mb-2">
                               We align with the most cutting-edge research to ensure that we have the best shot of enlivening students’ moral imagination and putting them on the path to be civically engaged through their entire lives.
                           </p>
                       </div>
                       <div className="pl-4 relative mobile:pl-8 mobile:hidden">

                           <div className="absolute edges top-1-n bottom-1-n w-100 bg-contain bg-no-repeat bg-center" style={{'backgroundImage' : `url(${require('../css/deedly/about-stars2.png')})`}}>

                           </div>

                           <HexMask image={require("../css/deedly/About0.jpg")} id="about0" />

                       </div>
                   </div>
               </div>
               <div className="mw-1360px relative mhor-auto blue br mt-4 p-3 mobile:p-2 mobile:mhor mobile:mt-2">
                   <div className="absolute left-0 bottom-1-n sq-15rem bg-contain bg-no-repeat bg-center mobile:hidden" style={{'backgroundImage' : `url(${require('../css/deedly/star-gemini.png')})`, top: `${-scrollTop/10 + 250}px`}}>
                   </div>
                   <div className="p-3 mhor-auto mobile:p-0">
                       <div className="grid7 col-gap-3 mobile:block">
                           <div className="col-span2 mobile:hidden">
                               <HexMask image={require("../css/deedly/About1.jpg")} id="about1" />
                           </div>
                           <div className="col-span5 flex align-center t-spaceblue mobile:mhor-0">
                               <div>
                                   <h2 className="mb">
                                       What We Do
                                   </h2>
                                   <ul className="f-medium lh-150 mobile:pl">
                                       <li className="mb">
                                           We tell compelling stories that active students idealism
                                       </li>
                                       <li className="mb">
                                           We partner with experts who have a proven track record of affecting positive change in strategic areas outlined in our stories
                                       </li>
                                       <li className="mb">
                                           We create supplemental curriculum, checks for understanding, and immersive activities that bring the highlighted issue closer to home for students, while aligning all of this to  state standards.  We aim to relieve some of the burden put on teachers, especially as it relates to civic and Social Emotional Learning
                                       </li>
                                       <li className="mb">
                                           We gamify the process to incentivize students
                                       </li>
                                       <li className="mb">
                                           We support teachers by creating a feedback loop, and offer them opportunities to use their leadership skills to guide our process throughout
                                       </li>
                                       <li className="mb">
                                           We align with the most cutting edge research to ensure we have the best shot of enlivening students' moral imagination, and putting them on the path to be civically engaged through their entire lives
                                       </li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>

               <div className="spaceblue">
                   <div className="mw-1056px m-auto pt-4 pb-6 relative">
                       <h3 className="t-white h3 center mb-2">
                           Team
                       </h3>

                       <div className="flex wrap center t-white col-gap-4 row-gap-4 justify-center">
                           {data.items && data.items.map(
                               ({fields, sys})=>{
                                   return(
                                       <div className="shrinks-0 col3 mb-3 mobile:col2 cursor-pointer" key={sys.id} onClick={()=>getInfoPop(fields)}>
                                           <div className="w-8rem mhor-auto mb">
                                               <HexMask image={fields.photo.fields.file.url} id={sys.id} />
                                           </div>
                                           <h4 className="f-medium">
                                               {fields.name}
                                           </h4>
                                           <p className="mobile:phor-2">
                                               {fields.position}
                                           </p>
                                       </div>
                                   )
                               }
                           )}
                       </div>

                       <div className="flex wrap center t-white col-gap-4 row-gap-4 justify-center">
                           {team.items && team.items.map(
                               ({fields, sys})=>{
                                   return(
                                       <div className="shrinks-0 col3 mb-3 mobile:col2 cursor-pointer" key={sys.id} onClick={()=>getInfoPop(fields)}>
                                           <div className="w-8rem mhor-auto mb">
                                               <HexMask image={fields.photo.fields.file.url} id={sys.id} />
                                           </div>
                                           <h4 className="f-medium">
                                               {fields.name}
                                           </h4>
                                           <p className="mobile:phor-2">
                                               {fields.position}
                                           </p>
                                       </div>
                                   )
                               }
                           )}
                       </div>

                   </div>
               </div>
           </div>

           {
               popup ?
                   <PopupTeam close={openPopUp} data={infopop}/>
                   :
                   undefined
           }



       </>
    )
}

export default Page