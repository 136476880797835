import React, {useEffect, useState} from "react";
import HexMask from "../components/HexMask";
import useContentful from "../components/hooks/useContentful";
import {NavLink, useParams} from "react-router-dom";
import {createClient} from "contentful";
import contentfulToHtmlString from "../components/helper/contentfulToHtmlString"

const client=createClient({
    space:"ltnak4n9hwfw",
    accessToken:"AiKmFU3EbSp3vZLKMzH_z2dyBfBgSKg0OD2jva1tMHE"
});


function Page() {

    const [mounted, setMounted] = useState(false);
    useEffect(()=>{
        if (!mounted) {
            window.scrollTo(0,0);
            setMounted(true);
        }
    },[mounted]);

    const [curQuestion, setCurQuestion] = useState("");
    const [questions, setQuestions] = useState([]);
    let {cat} = useParams();

    useEffect(()=>{

        const fetchContentful=async()=>{
            try{
                const result = await client.getEntries({content_type : 'faqQuestion', "fields.category.sys.contentType.sys.id" : "faqCategory", "fields.category.fields.url" : cat});
                setQuestions(result.items);
            }catch(e){
                console.log("err", e);
            }

        };
        fetchContentful();

        return(()=>{
        })
    },[cat])

    const handleNav = ()=>{
        //console.log(cat);
    }

    const handleQuestion = (q)=>{
        setCurQuestion(q);
    }

    const {data} = useContentful({content_type : 'faqCategory', 'order' : 'fields.name'}, 'getEntries');

    return(
        <div className="spaceblue grows">

            <div className="mw-1360px relative mhor-auto">

                <div className="absolute left-0 top-1-n bottom-0 w-15rem bg-contain bg-no-repeat bg-top mobile:hidden" style={{backgroundImage : `url(${require("../css/deedly/star-scorpio.png")})`}}>
                </div>
                <div className="absolute right-0 top-1-n bottom-0 w-15rem bg-contain bg-no-repeat bg-top mobile:hidden top-10" style={{backgroundImage : `url(${require("../css/deedly/star-gemini.png")})`}} >
                </div>

                <div className="t-white center pt-3 pb-2">
                    <h1 className="mb relative mobile:h2">Frequently Asked Questions</h1>
                </div>
                <div className="mw-1056px relative mhor-auto">

                    <div className="pver-2 white br-half grid3 col-gap-3 mb-2 mobile:block mhor">
                        <div className="b-right b-grey-light">
                            <nav>
                                {data.items && data.items.map (
                                    ({fields, sys})=>{
                                        return(
                                            <NavLink to={`/faq/${fields.url}`} className="align-center flex f-medium pver phor t-spaceblue" activeClassName="green-lighter" onClick={handleNav} key={sys.id}>
                                                <span className="sq-2rem icon inline-flex mr-half" style={{backgroundImage : `url(${fields.icon.fields.file.url})`}}></span>
                                                {fields.name}
                                            </NavLink>
                                        )
                                    }
                                )}
                            </nav>

                        </div>

                        <div className="col-span2 mobile:b-top-5 mobile:b-spaceblue mobile:mt-2 mobile:pt-2">
                            <div className="pr-2 mobile:pl-2">
                                {questions && questions.map (
                                    ({fields,sys})=>{
                                        return(
                                            <div className="pver-2 b-bottom b-grey-light mobile:pver" key={sys.id}>
                                                <div className="flex align-center cursor-pointer" onClick={()=>{handleQuestion(fields.question)}}>
                                                    <h4 className="grows lh-120 t-spaceblue mobile:h5 mobile:pr">{fields.question}</h4>
                                                    <a className="shrinks">
                                                        <span className="icon sq-2rem icon-down inline-flex"></span>
                                                    </a>
                                                </div>

                                                {curQuestion === fields.question && <div className="lh-150 mt" dangerouslySetInnerHTML={{__html : contentfulToHtmlString(fields.answer)}}></div>}



                                            </div>
                                        )
                                    }
                                )}
                            </div>

                        </div>
                    </div>

                    <div className="center pb-6">
                        <h4 className="t-white mb mobile:phor-2">
                            Or contact us through our available means to listen to you
                        </h4>
                        <a href="mailto:support@deedly.com" className="button-large teal t-white br-1half phor-4">
                            support@deedly.com
                        </a>
                    </div>

                </div>

            </div>


        </div>
    )
}

export default Page