import React from 'react'
import {Link} from "react-router-dom";
import '../css/footer.css';

function Component() {
    return(
       // <footer className="spaceblue">
			 //
       //     <div className="pb-8 t-white mw-1056px mhor-auto grid4 pt-6 pb-6 list-none mobile:flex mobile:wrap mobile:p-2">
       //         <div className="w-100  mobile:mb-2">
       //             <Link to="/">
       //                 <img src={require('../css/deedly/logo.png')} alt="Deedly" className="h-3rem w-auto" />
       //             </Link>
       //         </div>
       //         <div className="mobile:mr-2">
       //             <h5 className="pb-half">
       //                 Deedly
       //             </h5>
       //             <ul className="p-0 m-0 list-none">
       //                 <li className="pver-half">
       //                     <Link to="/about" className="afooter">About</Link>
       //                 </li>
       //                 <li className="pver-half">
				// 									<Link to="/deedcoins" className="afooter">Deed Coins</Link>
       //                 </li>
       //                 <li className="pver-half">
				// 									<Link to="/donate" className="afooter">Donate</Link>
       //                 </li>
       //                 <li className="pver-half">
				// 									<Link to="/curriculum" className="afooter">Curriculum</Link>
       //                 </li>
       //                 {/*<li className="pver-half">
				// 									<Link to="/charities" className="afooter">Charities</Link>
       //                 </li>*/}
       //             </ul>
			 //
       //         </div>
       //         <div className="mobile:mr-2">
       //             <h5 className="pb-half">
       //                 Support
       //             </h5>
       //             <ul className="p-0 m-0 list-none">
       //                 <li className="pver-half">
				// 									<a className="afooter" href="mailto:contact@deedly.com">Contact Us</a>
       //                 </li>
       //                 <li className="pver-half">
				// 									<Link to="/privacy-policy" className="afooter">Privacy Policy</Link>
       //                 </li>
       //                 <li className="pver-half">
				// 									<Link to="/faq" className="afooter">FAQ</Link>
       //                 </li>
       //                 <li>
       //                     <a className="afooter" href="https://educators.deedly.com/ccpa"> Personal Information</a>
       //                 </li>
       //             </ul>
			 //
       //         </div>
       //         <div>
       //             <h5 className="pb-half">
       //                 Join
       //             </h5>
       //             <ul className="p-0 m-0 list-none">
       //                 <li className="pver-half">
				// 									<a className="afooter" href="https://students.deedly.com/">Students</a>
       //                 </li>
       //                 <li className="pver-half">
				// 									<a className="afooter" href="https://educators.deedly.com/" >Educators</a>
       //                 </li>
       //             </ul>
			 //
       //         </div>
			 //
			 //
       //     </div>
			 //
       // </footer>
	
			<footer className="spaceblue">
				<div className="box-footer">
					<div className="logo-footer none-min768f">
						<a href="https://www.deedly.com">
							<img src={require('../css/deedly/logo.png')} className="img-full-fluid" alt="Deedly" />
						</a>
					</div>
					<div className="grid-footer">
						<div className="logo-footer none768f">
							<a href="https://www.deedly.com">
								<img src={require('../css/deedly/logo.png')} className="img-full-fluid" alt="Deedly"/>
							</a>
						</div>
						<div className="grid-row10 total-center-520">
							<h2 className="font20 margin-bottom5">Deedly</h2>
							<nav className="nav-footer total-center-520">
								<Link to="/about">
									About
								</Link>
								<Link to="/deedcoins">
									Deed Coins
								</Link>
								<Link to="/donate">
									Donate
								</Link>
								<Link to="/curriculum">
									Curriculum
								</Link>
								<Link to="/charities">
									Charities
								</Link>
							</nav>
						</div>
						<div className="grid-row10 total-center-520">
							<h2 className="font20 margin-bottom5">Support</h2>
							<nav className="nav-footer total-center-520">
								<a href="mailto:contact@deedly.com">
									Contact Us
								</a>
								<Link to="/privacy-policy">
									Privacy Policy​
								</Link>
								<Link to="/faq">
									FAQ
								</Link>
								<a href="https://educators.deedly.com/ccpa" target="_blank">
									Personal Information
								</a>
				</nav>
			</div>
			
			<div class="grid-row10 total-center-520 ">
				<h2 class="font20 margin-bottom5">Join</h2>
				<nav class="nav-footer total-center-520">
					<a href="https://students.deedly.com/">Students</a>
					<a href="https://educators.deedly.com/" >Educators</a>
				</nav>
			</div>
						
						<div className="grid-row10 social520 total-center-520">
							<h2 className="font20 margin-bottom5">Follow</h2>
							<nav className="nav-footer">
								<div className="grid-social">
									<a className="icon-social" href="https://www.facebook.com/Deedlyeducation/" target="_blank">
										<img src={require('../css/deedly/icon-facebook.svg')} className="img-full-fluid" alt="Facebook" />
									</a>
									<a className="icon-social" href="https://www.instagram.com/deedlyeducation/?hl=en" target="_blank" >
										<img src={require('../css/deedly/icon-instagram.svg')} className="img-full-fluid" alt="Instagram" />
									</a>
									<a className="tw" href="https://twitter.com/DeedlyEdu" target="_blank">
										<img src={require('../css/deedly/icon-twitter.svg')} className="img-full-fluid" alt="Twitter" />
									</a>
								</div>
							</nav>
						</div>
		
		</div>
	
	</div>




</footer>
			
			
    )
}


export default Component
