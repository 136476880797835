import React, {useEffect, useState} from "react";

function Page() {

    const [mounted, setMounted] = useState(false);
    useEffect(()=>{
        if (!mounted) {
            window.scrollTo(0,0);
            setMounted(true);
        }
    },[mounted]);

    return(
        <div className="spaceblue grows">

            <div className="mw-1360px relative mhor-auto">

                <div className="grid2 t-white col-gap-3 mw-1056px mhor-auto pver-6 mobile:block mobile:pver-3">
                    <div className="mobile:phor mobile:center">
                        <h1 className="mb relative mobile:h2">Deed Coins</h1>
                        <p className="f-medium lh-150 pr-3 mobile:f-medium mobile:pr-0">
                            Deed Coins are a point system that Deedly converts into “currency.” Deed Coins represent a real dollar value ($0.01) and are earned on deedly.com.
                        </p>

                        <div className="p-3 br green mver-2 mobile:p-2">
                            <img src={require('../css/deedly/deedcoins1.png')} />
                            <h4 className="lh-120 mt-2 t-spaceblue">
                                Sponsors and Donors give money to Deedly.*
                            </h4>
                        </div>

                        <div className="p-3 br green mver-2 mobile:p-2">
                            <img src={require('../css/deedly/deedcoins3.png')} />
                            <h4 className="lh-120 mt-2 t-spaceblue">
                                After watching Deedly content, students earn Deed Coins by completing challenges and activities.
                            </h4>
                        </div>

                        <div className="p-3 br green mver-2 mobile:p-2">
                            <img src={require('../css/deedly/deedcoins5.png')} />
                            <h4 className="lh-120 mt-2 t-spaceblue">
                                Deedly converts Deed Coins into the corresponding dollar amounts, that amount is taken from the Deedly “vault,” and student donations are sent to our nonprofit partners.
                            </h4>
                        </div>
                    </div>
                    <div className="mobile:phor">

                        <div className="mb-3-n mobile:hidden">
                            <img src={require('../css/deedly/deedcoins-star.png')} />
                        </div>


                        <div className="p-3 br green mver-2 mobile:p-2">
                            <img src={require('../css/deedly/deedcoins2.png')} />
                            <h4 className="lh-120 mt-2 t-spaceblue">
                                Deedly converts donations into Deed Coins and stores them in the Deed “vault.”

                            </h4>
                        </div>

                        <div className="p-3 br green mver-2 mobile:p-2">
                            <img src={require('../css/deedly/deedcoins4.png')} />
                            <h4 className="lh-120 mt-2 t-spaceblue">
                                Students give Deed Coins to Deedly-vetted nonprofit partners — both as a class and individually.
                            </h4>
                        </div>

                        <p className="t-white">
                            *Deedly is not a fundraising site, and never will ask for money from students.
                        </p>

                    </div>
                </div>


                <div className="spaceblue-dark pver-8 mobile:pver-2">
                    <div className="mw-1056px mhor-auto">

                        <h2 className="mb-2 center t-white mobile:h3 mobile:mhor">How Students Earn Deed Coins</h2>

                        <div className="yellow br pver-3 phor-4 mb-4 w-100 flex t-spaceblue justify-center align-center f-medium mobile:mhor mobile:p mobile:mb-2 mobile:w-auto">
                            <div className="w-15rem mobile:w-8rem">
                                <img src={require('../css/deedly/10points.png')} className="h-auto mb-2 mobile:mb-0" />
                                <p className="center mobile:hidden">
                                    Get points by completing challenges
                                </p>
                            </div>
                            <div className="w-2rem mhor-3  mobile:w-2rem mobile:mhor">
                                <img src={require('../css/deedly/equal.png')} className="h-auto" />
                            </div>
                            <div className="w-15rem mobile:w-8rem">
                                <img src={require('../css/deedly/1deed-coin.png')} className="h-auto mb-2 mobile:mb-0" />
                                <p className="center mobile:hidden">
                                    Use Deed Coins to help causes of their choice
                                </p>
                            </div>

                        </div>

                        <div className="phor-8 flex mobile:phor mobile:block">
                            <div className="shrinks-0 mr-3 mobile:mb center mobile:mr-0">
                                <img src={require('../css/deedly/our-app.png')} className="h-auto" />
                            </div>

                            <div className="t-white center">
                                <h2 className="mb">Our App</h2>
                                <p className="lh-150 mb mobile:f-medium mobile:mb-2">
                                    Our app makes it easy to see how the donations from students, classes, and schools add up over time, creating a significant impact.
                                </p>
                                <div>
                                    <a className="mr">
                                        <img src={require('../css/deedly/appstore.png')} className="h-3rem w-auto" alt="Deedly app on the app store" />
                                    </a>
                                    <a>
                                        <img src={require('../css/deedly/googleplay.png')} className="h-3rem w-auto" alt="Deedly app on the google store" />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}

export default Page