import React, {useEffect, useState} from "react";
import useContentful from "../components/hooks/useContentful";
import {createClient} from "contentful";
import {Link} from "react-router-dom";

const client=createClient({
    space:"ltnak4n9hwfw",
    accessToken:"AiKmFU3EbSp3vZLKMzH_z2dyBfBgSKg0OD2jva1tMHE"
});

function Cur(props){

    const [load, setLoad] = useState("loading");
    const [id, setId] = useState("");
    const [data, setData] = useState([]);

    useEffect(()=>{

        if(id !== props.id){
            setId(props.id);
            //const {isLoading, isError, loaded, data} = useContentful({content_type : 'weeks', 'order' : 'fields.order', 'fields.curriculum.sys.contentType.sys.id' : 'weeks', "fields.curriculum.sys.id" : id}, 'getEntries');

            const fetchContentful=async()=>{
                setLoad("loading")
                try{
                    const result=await client.getEntries({content_type : 'weeks', 'order' : 'fields.order', 'fields.curriculum.sys.contentType.sys.id' : 'curriculum', "fields.curriculum.sys.id" : props.id});
                    console.log("RES", result);
                    setData(result);
                    setLoad("loaded")


                }catch(e){
                    setLoad("error")
                }

            };

            fetchContentful();
        }
        console.log("Curr Id", id, props.id);

    });


    return(
        <>
            {load === "loading" &&
            <div className="mh-50vh flex t-white justify-center align-center">
                <h2>Loading...</h2>
            </div>
            }

            {load === "loaded" &&

                <>
                    {data.items && data.items.length > 0 ?
                        <div className="grid2 t-spaceblue col-gap-3 row-gap-3 mobile:block mobile:phor">
                            {data.items.map( ({fields, sys}) => {
                                return(
                                    <Link className="br overflow-hidden mobile:mb-2" key={sys.id} to={`/curriculum/${sys.id}`}>
                                        <div className="h-20rem relative">
                                            <img src={fields.cover.fields.file.url} className="absolute edges obj-cover w-100 h-100" alt="Self" />
                                        </div>
                                        <div className={`flex align-center p`} style={{backgroundColor : `#${fields.color}`}}>
                                            <span className="icon inline-flex sq-3rem mr" style={{backgroundImage : `url(${fields.icon.fields.file.url})`}}></span>
                                            <div className="t-spaceblue">
                                                <h4 className="mb-0">{fields.title}</h4>
                                                <p>View More</p>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}

                        </div>

                        :
                        <div className="mh-50vh flex t-white justify-center align-center">
                            <h2>Coming Soon...</h2>
                        </div>
                    }

                </>
            }


        </>

    )
}

export default Cur
