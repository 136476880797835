import React from "react";

function HowItWorks() {

    return(
        <div className="mw-1056px m-auto pb-3 relative mobile:pb-2">
            <h3 className="t-white h3 center mb-2 pt-6 mobile:pt-2">
                How Deedly Works
            </h3>
            <div className="grid3 center t-white mobile:block mobile:phor-2 mobile:mhor-auto">
                <div className="phor-2 mobile:mb-2">
                    <div className="w-8rem mhor-auto mb-2">
                        <img className="w-100" src={require('../css/deedly/icon-signup.png')}  />
                    </div>
                    <h4 className="h4">
                        Sign up
                    </h4>
                    <p className="lh-150">
                        Signing up your class is simple! In less than two minutes, you can sign all your students  up for captivating online learning that can be used for in-person for online classes.

                    </p>
                </div>
                <div className="phor-2 mobile:mb-2">
                    <div className="w-8rem mhor-auto mb-2">
                        <img className="w-100" src={require('../css/deedly/icon-educate.png')}  />
                    </div>
                    <h4 className="h4">
                        Educate
                    </h4>
                    <p className="lh-150">
                        Educators can activate multiple classes, choosing from a number of interactive videos and collaborative projects. Each course covers not only the essential state standards, but explores social-emotional skills and 21st Century problems as well.

                    </p>
                </div>
                <div className="phor-2">
                    <div className="w-8rem mhor-auto mb-2">
                        <img className="w-100" src={require('../css/deedly/icon-give.png')}  />
                    </div>
                    <h4 className="h4">
                        Deed Coins
                    </h4>
                    <p className="lh-150">
                        Students unlock Deed Coins through their daily curriculum. Corporate sponsors turn the coins students accumulate into dollars, and students get to donate those “coins” to partner charities of their choosing.
                    </p>
                </div>

            </div>
        </div>
    )

}


export default HowItWorks