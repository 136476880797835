import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import {createClient} from "contentful";

const client=createClient({
    space:"ltnak4n9hwfw",
    accessToken:"AiKmFU3EbSp3vZLKMzH_z2dyBfBgSKg0OD2jva1tMHE"
});

function Next(props){
    const { onClick } = props;
    return(
        <div className="arrow sq-3rem br-50 spaceblue flex align-center justify-center left-auto right-4-n next mobile:sq-2 mobile:right-3-n" onClick={onClick}>
            <a className="icon sq-1halfrem icon-next"></a>
        </div>
    )
}

function Prev(props){
    const { onClick } = props;
    return(
        <div className="arrow sq-3rem br-50 spaceblue flex align-center justify-center prev left-4-n mobile:sq-2 mobile:left-3-n" onClick={onClick}>
            <a className="icon sq-1halfrem icon-prev"></a>
        </div>
    )
}



function Partners(props){

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <Next/>,
        prevArrow: <Prev/>
    };

    const [charities, setCharities] = useState([]);
    const [loaded, setLoaded] = useState(false)

    useEffect(()=>{

        const fetchContentful=async()=>{
            try{
                const result = await client.getEntries({content_type : 'charities', "fields.featured" : true, order : "fields.order"});
                setCharities(result.items);
                setLoaded(true);

            }catch(e){
                console.log("err", e);
            }

        };
        fetchContentful();

        return(()=>{
        })
    },[loaded])


    return(
        <div className="relative mobile:p">

            <div className="mt-3 grid3 mobile:col-gap mobile:mt">
                {charities.length > 0 && charities.map(
                    ({fields,sys})=>{
                        return(
                            <a href={fields.charityLink} target="_blank" className="flex align-center phor-2 pb" key={sys.id}>
                                <img src={fields.logo && fields.logo.fields.file.url} alt={fields.title} />
                            </a>
                        )

                    }
                )}

                {/*<Slider {...settings}>

                </Slider>*/}


            </div>
        </div>

    )
}

export default Partners
