import React, {useEffect, useState} from "react";
import {createClient} from "contentful";
import {useParams, Link} from "react-router-dom";
import contentfulToHtmlString from "../components/helper/contentfulToHtmlString";

const client=createClient({
    space:"ltnak4n9hwfw",
    accessToken:"AiKmFU3EbSp3vZLKMzH_z2dyBfBgSKg0OD2jva1tMHE"
});

function CurDetail(props){

    let { id } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState({});

    useEffect(()=>{

        if (!loaded){
            fetchContentful();
        }

    }, [loaded]);

    const fetchContentful = async () => {
        try{
            const result = await client.getEntry(id);
            console.log("RES", result);
            setData(result.fields);
            setLoaded(true)

        }
        catch(e){
            console.log(e);
            setLoaded(true)
        }
    }


    return(
        <div className="spaceblue w-100">
            {data &&
                <div className="mw-1056px mhor-auto flex grows w-100 white overflow-hidden br-2">
                    <div className="pt-2 grows" style={{backgroundColor : `#${data.color}`}}>
                        <h2 className={`phor t-spaceblue pb`}>
                            {data.cover && <img src={data.icon.fields.file.url} alt={data.title} className="w-4rem h-auto" />}
                            {data.title}
                        </h2>
                        {console.log("COVER", data.cover)}
                        {data.cover && <img src={data.cover.fields.file.url} alt={data.title} className="w-100 h-auto" />}
                        <div className="pver-2 phor-2 white">
                            <div className="lh-150 mt description content-detail-curriculum" dangerouslySetInnerHTML={{__html : contentfulToHtmlString(data.description)}}></div>
                        </div>

                    </div>

                </div>
            }
        </div>

    )
}

export default CurDetail
