import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import HowItWorks from "../components/HowItWorks";
import {createClient} from "contentful";
import contentfulToHtmlString from "../components/helper/contentfulToHtmlString"
import Partners from "../components/Partners";

function Page() {

    const [loaded, setLoaded] = useState(false);
    useEffect(()=>{
        if (!loaded) {
            window.scrollTo(0,0);
            setLoaded(true);
        }
    },[loaded]);

    const [video, setVideo] = useState(false);
    const handleVideo = ()=>{
        setVideo(!video);
    }

    const [scrollTop, setScrollTop] = useState(100);

    useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    return(
        <div className="page spaceblue grows">

            <div className="mt mhor-auto phor-4 relative mobile:phor">
                <div className="mw-1360px green mhor-auto br bg-cover mobile:bg-contain mobile:bg-no-repeat mobile:bg-center">
                    <div className="grid5 mobile:block">
                        <div className="col-span3 p-3 mobile:p-0">
                            <div className="w-100">
                                <a onClick={handleVideo}>
                                    <img src={require('../css/deedly/hero-video.png')} className="w-100" />
                                </a>
                                {video &&
                                <div className="fixed z-1000 edges flex align-center justify-center">
                                    <div className="black90 edges fixed z-10" onClick={handleVideo}></div>
                                    <div className="mw-1200px mhor-auto relative z-20">
                                        <video className="w-100" controls={true} autoPlay={true}>
                                            <source  type="video/mp4" src="https://deedly.s3-accelerate.amazonaws.com/552b31c4a7a18284592ca33746f96b16_introv2.mp4"/>
                                        </video>

                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="col-span2 flex direction-column grows mobile:p pt-3 pr-3">
                            <div>
                                <h1 className="h2 mb-0 t-spaceblue w-100 lh-120 mobile:h2 mobile:lh-100">
                                    Welcome to Deedly
                                </h1>
                                <p className="f-large mb t-spaceblue w-100 lh-120">
                                    Captivating storytelling video content that leverages educational standards to activate the mind — and the heart.
                                </p>
                                <p className="pb-2 mobile:pb-0">
                                    <a href="http://educators.deedly.com" className="button button-large teal-dark t-white phor-4 inline-flex br-2 hover:teal">Get Started Now</a>
                                </p>

                            </div>
                            <div className="grows relative mobile:hidden">
                                <div className="absolute top-0 bottom-0 right-0 left-0 overflow-hidden">
                                    <svg className="w-100" viewBox="0 0 604 637" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M271.678 8.24302C290.704 -2.0706 313.653 -2.0706 332.679 8.24302L570.071 136.93C590.713 148.12 603.57 169.714 603.57 193.195V443.745C603.57 467.225 590.713 488.82 570.071 500.01L332.679 628.696C313.653 639.01 290.704 639.01 271.678 628.696L34.2871 500.01C13.6449 488.82 0.787506 467.225 0.787506 443.745V193.194C0.787506 169.714 13.6449 148.12 34.2871 136.93L271.678 8.24302Z" fill="#217A6A"/>
                                    </svg>
                                </div>
                                <div className="absolute mw-100 flex justify-center">
                                    <div className="mw-60 h-auto">
                                        <img src={require("../css/deedly/deedo.png")} />
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>

            </div>

            <div className="spaceblue">
                <div className="mw-1360px mhor-auto relative pb-2">
                    <div className="absolute left-0 top-3 bottom-0 w-15rem bg-contain bg-no-repeat bg-top mobile:hidden" style={{backgroundImage : `url(${require("../css/deedly/star-scorpio.png")})`, top: `${-scrollTop/10 + 50}px`}}>
                    </div>
                    <div className="absolute right-0 top-8 bottom-0 w-15rem bg-contain bg-no-repeat bg-top mobile:hidden" style={{backgroundImage : `url(${require("../css/deedly/star-gemini.png")})`, top: `${-scrollTop/5 + 200}px`}}>
                    </div>

                    <HowItWorks/>

                </div>
            </div>
            <div className="spaceblue-dark">
                <Link to="/curriculum">
                    <div className="mw-1056px mhor-auto pt-6 grid3 col-gap-3 t-white mobile:pt-2 mobile:block mobile:phor">
                        <div className="p-2 br center blue t-spaceblue mobile:mb flex justify-center align-center">
                            <div>
                                <h4 className="h4">
                                    Middle School Curriculum
                                </h4>
                                <p>
                                    High school curriculum coming soon…
                                </p>
                            </div>

                        </div>
                        <div className="p-2 br center red t-spaceblue mobile:mb flex justify-center align-center">
                            <div>
                                <h4 className="h4">
                                    20 Video Lessons
                                </h4>
                                <p>
                                    10 Minutes average
                                </p>
                            </div>

                        </div>
                        <div className="p-2 br center green t-spaceblue flex justify-center align-center mobile:mb">
                            <div>
                                <h4 className="h4">
                                    4 Week Curriculum
                                </h4>
                                <p>
                                    5 videos per week
                                </p>
                            </div>

                        </div>
                    </div>
                </Link>

                <div className="mw-1056px mt-4 mb-8 m-auto mobile:p-2 mobile:m p-4 yellow br t-spaceblue">
                    <h3 className="h3 center mb-2 mobile:h4">
                        What educators are saying
                    </h3>
                    <div className="grid2 col-gap-3 mobile:block">
                        <div>
                            <p className="f-italic lh-150 f-large f-700">
                                “Deedly is a fun way for students to better understand global issues that affect us all. Thanks to Deedly, students develop empathy and learn actions they can take to help create the world we all want. The stories presented have touched the heart and sensitivity of each of my students and motivated them to be better citizens.”
                            </p>
                            <p className="right mt-2">
                                Annabel L. Rivera-Colon, MIS<br />
                                Escuela Superior Vocacional Ruth E. Cruz Santos<br />
                                Maestra Bibliotecaria
                            </p>
                        </div>
                        <div>
                            <p className="f-italic lh-150 f-large f-700">
                                “Deedly is a remarkable learning and teaching platform. Our students learned about 21st century global humanitarian and environmental issues. They learned that even from across the globe, we can and do make a difference. We used Deedly as inspiration in our ESL and geography classes, scaffolding the material we learned with research and hands on projects, while of course having fun! Using Deedly touched our hearts, leaving us with a yearning to learn more, do better and give back! ”
                            </p>
                            <p className="right mt-2">
                                Bracha Vaknin
                                <br />
                                Darca Hammer School <br />
                                English Teacher
                            </p>
                        </div>
                        {/*<div>
                            <p className="f-italic lh-150 f-large f-700">
                                “Using Deedly Students are able to become global citizen and learn of projects that help the world. My class loved using this curriculum to learn about issues around the world; and by being a part gave the students a desire to help out their fellow man.”
                            </p>
                            <p className="right mt-2">
                                Daniel Benitez,<br />
                                SLAM CharterSchool, <br />
                                Teacher
                            </p>
                        </div>


                        <div className="mobile:hidden">
                            <p className="f-italic lh-150 f-large f-700">
                                “A few teachers and I used Deedly in our Advi-
                                sory Class and it was a great compliment to the other things we were working on. I think it would be great to use in Advisory again moving forward.”
                            </p>
                            <p className="right mt-2">
                                David DeFrenza, <br />
                                Los Angeles Academy of Arts and Enterprise, <br />
                                Dean of students
                            </p>
                        </div>*/}

                    </div>


                </div>

                <div className="mw-1056px blue mhor-auto br mb-4 mt-6 relative mobile:mhor mobile:mb-2 mobile:mt-2">
                    <div className="absolute w-50 top-4 bottom-4 left-4 bg-contain bg-center bg-no-repeat" style={{backgroundImage : `url(${require('../css/deedly/stars.png')})`}}></div>
                    <div className="grid7 mobile:block">
                        <div className="col-span3 pt-4 pb-4 pl-4 pr-2 mobile:p-2">
                            <h3 className="t-spaceblue-dark mb-2 mobile:center">
                                Educators and students can connect on iOS, Android and Web
                            </h3>
                            <a className="mb block mobile:center">
                                <img src={require('../css/deedly/appstore.png')} className="w-15rem mobile:w-10rem h-auto" alt="Deedly app on the app store" />
                            </a>
                            <a className="block mobile:center">
                                <img src={require('../css/deedly/googleplay.png')} className="w-15rem mobile:w-10rem h-auto" alt="Deedly app on the google store" />
                            </a>
                        </div>
                        <div className="col-span4 relative">
                            <div className="absolute left-2 right-8 bottom-0 mobile:static mobile:phor-3">
                                <img src={require('../css/deedly/phone-home.png')} className="w-100 h-auto" alt="Deedly app" />
                            </div>

                        </div>

                    </div>
                </div>

                <div className="mw-1056px mhor-auto t-white pt-4 pb-8 mobile:pb-2 mobile:pt-2 mobile:phor-2">
                    <h3 className="center mb">Give and Help Others</h3>
                    <p className="f-large center mobile:f-medium">
                        We know you're busy! Setup is simple and lessons take students under an hour per week.
                    </p>
                    <div className="grid3 pver-2 mobile:block">
                        <div className="center mobile:mb-2">
                            <h3 className="h0">
                                331
                            </h3>
                            <h3>Schools</h3>
                        </div>
                        <div className="center mobile:mb-2">
                            <h3 className="h0">
                                4,864
                            </h3>
                            <h3>Students</h3>
                        </div>
                        <div className="center">
                            <h3 className="h0">
                                691
                            </h3>
                            <h3>Classes</h3>
                        </div>
                    </div>
                    <p className="center pb-2 mobile:pb-0">
                        <a className="button button-large teal t-white phor-4 inline-flex br-2 hover:teal-dark">Sign up now</a>
                    </p>

                </div>

                <div className="mw-1360px mhor-auto relative">
                    <div className="absolute left-0 top-0 w-15rem h-15rem bg-no-repeat bg-contain bg-center mobile:w-10rem mobile:h-10rem" style={{backgroundImage : `url(${require("../css/deedly/star-gemini.png")})`, top: `${-scrollTop/10}px`}}>

                    </div>

                    <div className="mw-1056px mhor-auto pb-6 mobile:p">
                        <div className="p-4 white br mobile:p">
                            <div className="flex justify-center">
                                <div className="relative phor-6 mobile:phor-2">
                                    <h3 className="t-spaceblue center mobile:h4 mobile:pt">
                                        Partner Non-Profits
                                    </h3>
                                    <Partners />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>


        </div>
    )
}

export default Page
