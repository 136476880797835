import React,{useEffect,useState} from "react";
import {createClient} from "contentful";
import PopupCharities from "../components/popupCharities";

let client=createClient({
	space:"ltnak4n9hwfw",
	accessToken:"AiKmFU3EbSp3vZLKMzH_z2dyBfBgSKg0OD2jva1tMHE"
});



const Charities = ({}) => {
	const [load, setLoad] = useState(false);
	const [data, setData] = useState([]);
	
	const [popup, setPopup] = useState(false);
	const [infopop, setInfoPop] = useState('');
	
	
	
	useEffect(()=>{
		window.scrollTo(0, 0);
		client.getEntries({content_type : 'charities', 'order' : 'fields.order' }).then(
			(entries)=>{
				let news=entries.items;
				setData(news);
				setLoad(true);
				// console.log('info content', data)
			}
		);
	}, [load])
	
	
	const openPopUp=()=> {
		setPopup(!popup)
	}
	
	const getInfoPop=(pos)=> {
		setPopup(!popup)
		setInfoPop(data[pos])
	}
	
	
	return (
		<>
			<div className="container40 w-100">
				<div className="mw-800px margin-auto m-auto w-100">
					<div className="grid-row30">
						<h1 className="center">Our Charities</h1>
						{
							load ?
								<div className="grid-charities">
									{
										data && data.length > 0 ?
											<>
											{
												data.map((i, j)=>{
													return (
														<div className="grid-row10 content-start" key={i.sys.id} onClick={()=>getInfoPop(j)}>
															<div className="box-charity">
																<img src={i.fields.logo.fields.file.url} className="img-object" alt={i.fields.title} />
															</div>
															<p className="center">{i.fields.title}</p>
														</div>
													)
												})
											}
											</>
											:
											undefined
									}
								</div>
								:
								undefined
						}
					</div>
					{
						popup ?
							<PopupCharities close={openPopUp} data={infopop}/>
							:
							undefined
					}
				</div>
			</div>
		</>
	);
};

export default Charities;
