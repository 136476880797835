import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";

function Component() {

    const [menu, setMenu] = useState(false);
    const handleMenu = ()=>{
        setMenu(!menu)
    }

    const handleLink = ()=>{

    }

    useEffect(()=>{
        if(menu){
            window.onclick = function(event) {
                setMenu(false)
            }
        }
        else{
            window.onclick = function(event) {

            }
        }
    })

    return(
        <div>
            <header className="flex h-4rem spaceblue">
                <div className="grows shrinks-0 flex align-center">
                    <div className="phor">
                        <Link to="/">
                            <img src={require('../css/deedly/logo.png')} alt="Deedly" className="h-3rem mobile:h-2halfrem w-auto" />
                        </Link>
                    </div>

                </div>
                <div className="shrinks-0 flex justify-end">
                    <div className="flex align-center">
                        <a href="https://educators.deedly.com" className="button-small blue2 t-white br-2 mr hover:blue2-dark">
                            Educators
                        </a>
                        <a href="https://students.deedly.com" className="button-small teal t-white br-2 mr hover:teal-dark">
                            Students
                        </a>
                    </div>
                    <div className="flex relative">
                        <a className="flex align-center t-white pr op-75 hover:op-100" onClick={handleMenu}>
                            <span className="mr-half mobile:hidden">Menu</span>
                            {menu ?
                                <i className="icon sq-1halfrem icon-x"></i>
                                :
                                <i className="icon sq-1halfrem icon-menu"></i>
                            }

                        </a>
                        {menu ?
                            <nav className="absolute top-4 right-1 w-200px spaceblue-dark br z-200 childs-block f-1rem">
                                <Link to="/" className="p t-white b-bottom b-white10 op-75 hover:op-100" onClick={handleMenu}>
                                    Home
                                </Link>
                                <Link to="/about" className="p t-white b-bottom b-white10 op-75 hover:op-100" onClick={handleMenu}>
                                    About
                                </Link>
                                <Link to="/curriculum" className="p t-white b-bottom b-white10 op-75 hover:op-100" onClick={handleMenu}>
                                    Curriculum
                                </Link>
                                <Link to="/donate" className="p t-white b-bottom b-white10 op-75 hover:op-100" onClick={handleMenu}>
                                    Donate
                                </Link>
                                <Link to="/deedcoins" className="p t-white b-bottom b-white10 op-75 hover:op-100" onClick={handleMenu}>
                                    Deed Coins
                                </Link>
                                <Link to="/faq" className="p t-white b-bottom b-white10 op-75 hover:op-100" onClick={handleMenu}>
                                    FAQ
                                </Link>
                                <a href="mailto:contact@deedly.com" className="p t-white b-bottom b-white10 op-75 hover:op-100">
                                    Contact
                                </a>

                            </nav>
                            :
                            null
                        }
                    </div>
                </div>


            </header>
        </div>

    )
}

export default Component