import React, {useEffect} from 'react';



const PrivacyPolicy = ({}) => {
	
	useEffect(()=>{
		window.scrollTo(0, 0);
	})
	
	return (
		<>
			<div className="container40 w-100">
				<div className="mw-800px margin-auto m-auto w-100">
					<div className="grid-row20">
						<h1 className="center">Privacy and Policy</h1>
						<div>
							<p>Deedly Privacy Policy</p>
							<p>Updated May 1, 2020</p>
						</div>
						<h2 className="font30">Thank You</h2>
						<p>Welcome to Deedly! We appreciate you being here, and we recognize that you care about your personal information, where it’s shared, and how it’s protected. We do, too. That’s why we’ve put together this easy-to-understand Privacy Policy that explains just that.</p>
						<p>By accessing or using the “Services” (meaning either our website, including the mobile version of the website, or the Deedly mobile app available via the Apple App Store and the Android Marketplace), you acknowledge that you accept the practices and policies outlined in this Privacy Policy, as well as the accompanying COPPA Notice: Direct Notice to Schools and Parents, and that your usage of the Services is subject to Deedly’s Terms of Service - all of which may be updated from time to time.</p>
						<p>If you have any questions or concerns regarding our privacy practices, please send a detailed message to <a className="a-bl" href="mailto:contact@deedly.com">contact@deedly.com.</a></p>
						<h2 className="font30">Purpose of This Privacy Policy</h2>
						<p>Deedly will use the data collected through your use of the Services only as detailed and set forth in this Privacy Policy. The purpose of this Privacy Policy is to provide our users with a clear understanding of how Deedly collects, uses, and safeguards your information.</p>
						<p>When Deedly makes significant changes to this Privacy Policy, registered users of the Services will be notified and will need to accept such amended terms in order to continue their usage of the Services.</p>
						<h2 className="font30">Brief Overview</h2>
						<p>You’re busy. Privacy Policies are, quite frankly, boring. We hope that you take the time to read this in its entirety, but, in the event that you are a human and not a lawyer or robot, here’s a brief overview:</p>
						<div className="pad-l20 margin-bottom10">
							<div className="grid-ex-in">
								<p>●</p>
								<p>Deedly does collect some personal information from our users. We use this information to operate our Services, stay in touch with our users, connect you with our awesome Charity Partners, and as otherwise detailed below.</p>
								<p>●</p>
								<p>We do NOT, and will NEVER, sell your personal information or use it for any advertising purposes.</p>
								<p>●</p>
								<p>We’re committed to protecting your personal information and take reasonable precautions to do so.</p>
								<p>●</p>
								<p>If you need to delete, amend, or access your personal information please do so using the following Web Form.</p>
								<p>●</p>
								<p>If you have any other questions, please reach out via email at <a className="a-bl" href="mailto:contact@deedly.com">contact@deedly.com.</a></p>
							</div>
						</div>
						<h2 className="font30">What Personal Information Does Deedly Collect & How Do We Do So?</h2>
						<p>Through your usage of the Services, Deedly does collect a certain amount of your personal information. For purposes of this Privacy Policy, “personal information” means information that can be used to identify you or that can be easily linked to you. </p>
						<p>Deedly collects personal information from users of the Services in three ways. First, there are “cookies” (not the delicious kind, unfortunately) that may collect personal information as you browse the deedly.com website. Secondly, in order to become a registered user of the Services, Deedly collects certain personal information as part of the sign-up process. Third and finally, certain personal information may be collected if you elect to log in to the Deedly services using a third party social media account, such as Facebook. All three of these personal information collection methods are detailed below.</p>
						<p>Although we are not legally required to collect personal information from our users, the collection of certain personal information is necessary for the operation of the Services. For instance, we require the first and last names (instead of remaining anonymous or using pseudonyms) of our “Student Users” because we must ensure that we’ve obtained the proper consents required for such Student Users to share their personal information - more on this below.</p>
						<p><span className="f-underline">Cookies, Analytics, Do Not Track & Similar Functionality</span> When you visit the Deedly website, basic information is passively collected through your web browser via use of tracking technologies, such as “cookies” (again, not the chocolate chip kind) that utilize various files sent from our servers to your computer and hard drive. Additional information about cookies and tracking technologies can be found here. In addition to cookies, the Deedly website also utilizes certain analytics functionality.</p>
						<p>When you visit the Deedly website, you are presented with the option to disable certain cookies. However, please note that, if you do elect to have such cookies disabled, you may not be able to use some portions of the website.</p>
						<p>More specifically, Deedly uses Google Analytics on our website. This involves the collection of the following information:</p>
						<div className="pad-l20 margin-bottom10">
							<div className="grid-ex-in">
								<p>●</p>
								<p>Online identifiers, including cookie identifiers;</p>
								<p>●</p>
								<p>IP addresses and device identifiers; and</p>
								<p>●</p>
								<p>Client identifiers</p>
							</div>
						</div>
						<p>To minimize the amount of personal information being transmitted to Google Analytics, Deedly has anonymized your IP address and also utilized pseudonymous identifiers within Google Analytics.</p>
						<p><span className="f-underline">Information You Provide In Signing Up For, And Using, The Services</span> In order to gain access to and engage with the content and curriculum provided by Deedly, our users need to sign up and create accounts that they can then log into. More specifically, Deedly has two such types of users.</p>
						<p>First, we have our “Educator Users.” Such individuals may be school teachers or administrators and are creating accounts so that they can manage the usage of Deedly amongst their students. Deedly obtains consent to the collection of their personal information by requiring such individuals to affirmatively agree to this Privacy Policy before an educator can create their Deedly account. </p>
						<p>Deedly collects the following personal information from our Educator Users:</p>
						<div className="pad-l20 margin-bottom10">
							<div className="grid-ex-in">
								<p>●</p>
								<p>First and last name;</p>
								<p>●</p>
								<p>Email address;</p>
								<p>●</p>
								<p>Phone number (please note that providing this information is optional);</p>
								<p>●</p>
								<p>User-generated password;</p>
								<p>●</p>
								<p>The school and school district that the Educator is employed by;</p>
								<p>●</p>
								<p>Date of birth (please note that providing this information is optional); and</p>
								<p>●</p>
								<p>City of residence (please note that providing this information is optional).</p>
							</div>
						</div>
						<p>Second, we have our “Student Users.” These individuals are K-12 students and are creating accounts so that they can engage with the curriculum, learn about our charity partners, make donation decisions, and otherwise avail themselves of the Services. Deedly obtains consent to the collection of the personal information of our Student Users in two ways. </p>
						<p>In certain situations, teachers or school administrators may be able to provide consent to a Student User creating a Deedly account. In doing so, Deedly requires that the teacher or school administrator confirm their authority to provide such consent and then affirmatively indicate such consent in a written, signed document.</p>
						<p>In other situations, Deedly obtains consent for our Student Users to create accounts directly from the parent or legal guardian of the student. In doing so, Deedly requires the parent or guardian of the student to affirmatively indicate their consent in a written, signed document.</p>
						<p>Once consent is properly obtained via one of the two methods described above, the teacher responsible for their Student Users will provide such users with a unique class code to verify the registration and ensure that only properly authenticated and consenting users can access the Deedly Services. </p>
						<p>Deedly collects the following personal information from our Student Users:</p>
						<div className="pad-l20 margin-bottom10">
							<div className="grid-ex-in">
								<p>●</p>
								<p>A user-generated username;</p>
								<p>●</p>
								<p>A user-generated password;</p>
								<p>●</p>
								<p>Email address;</p>
								<p>●</p>
								<p>The school and school district that the student attends;</p>
								<p>●</p>
								<p>Date of birth (please note that providing this information is optional);</p>
								<p>●</p>
								<p>Gender (please note that providing this information is optional); and</p>
								<p>●</p>
								<p>City of residence (please note that providing this information is optional).</p>
							</div>
						</div>
						<div>
							<p className="f-underline">Linked Social Media Accounts</p>
							<p>Deedly allows our users to access the Services through their existing social media accounts. More specifically, Student and Educator Users can log in to Deedly via:</p>
						</div>
						<div className="pad-l20 margin-bottom10">
							<div className="grid-ex-in">
								<p>●</p>
								<p>Facebook;</p>
								<p>●</p>
								<p>Instagram; or</p>
								<p>●</p>
								<p>Google Plus</p>
							</div>
						</div>
						<p>If you register or log in to the Deedly Services through a third-party social media service, we will have access to some of your third-party account information, such as your name and other information in that account, as authorized by the particular service. If you do not wish for Deedly to have access to such information, please do not use the above social media platforms to log in to our Services. You have the ability to disable the connection between our Services and your third-party social media account at any time.</p>
						<p>For further information on how Deedly collects and uses personal information of our Student Users under the age of 13, please review our COPPA Notice: Direct Notice to Schools and Parents.</p>
						<h2 className="font30">How Deedly Uses Your Personal Information Ourselves</h2>
						<p>Deedly uses your personal information internally as follows:</p>
						<div className="pad-l20 margin-bottom10">
							<div className="grid-ex-in">
								<p>●</p>
								<p>To provide the Services in accordance with the specific purpose for which you have provided such information;</p>
								<p>●</p>
								<p>To verify your identity and confirm that all necessary consents have been obtained for your use of the Services;</p>
								<p>●</p>
								<p>To troubleshoot any technical issues you might experience while using the Services;</p>
								<p>●</p>
								<p>To send you communications about the Services and other information we think may interest you, when we have the appropriate permissions;</p>
								<p>●</p>
								<p>To better understand how our users utilize and access the Services, in order to improve the Services;</p>
								<p>●</p>
								<p>To develop aggregated reports and related analysis regarding user activities (more on this below);</p>
								<p>●</p>
								<p>To comply with applicable legal obligations, including responding to a subpoena or court order; and</p>
								<p>●</p>
								<p>Where we believe it is necessary to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, or violations of Deedly’s Privacy Policy or Terms of Service.</p>
							</div>
						</div>
						<p>Deedly does not, and will never, use personal information of our Student Users to build a “profile” of such users, other than for supporting an authorized educational purpose, or as explicitly authorized by and consented to by the student’s school and/or parent or legal guardian.</p>
						<h2 className="font30">Sharing of Your Personal Information With Others</h2>
						<p>From time to time, Deedly may need to provide your personal information with third parties. Please see details of this below.</p>
						<p>First and foremost, Deedly does not, and will never, sell your personal information. None of your personal information will be used for targeted advertising or marketing.</p>
						<div>
							<p className="f-underline">Schools</p>
							<p>If you access the Services through your school (or school district), we may share the information we collect about you with the school and/or school district.</p>
						</div>
						<div>
							<p className="f-underline">Service Providers</p>
							<p>Deedly may employ independent contractors, and/or engage the services of vendors and suppliers to provide specific services related to the Services. This may include hosting and maintaining the Service and similar functionality. In such instances, your personal information may be shared with such third parties for the specific purpose of enabling the performance and enhancement of the Services.</p>
						</div>
						<div>
							<p className="f-underline">Charity Partners</p>
							<p>The Deedly Services involve partnerships with various charities (our “Charity Partners”). Users of the Services learn about such Charity Partners through the Deedly curriculum and can make decisions as to whether they want to donate to such third parties. As such, Deedly may share certain aggregated, de-identified information with our Charity Partners. This may include details of donation preferences, user feedback on such partners, and similar information. No personal information of our users will be shared with Charity Partners.</p>
						</div>
						<div>
							<p className="f-underline">International Transfers</p>
							<p>Regardless of where a user located, their personal information will be transferred to, and stored within, the United States of America. We are not able to process and store your information within the borders of any other country. The U.S. may not offer an equivalent level of data protection as in your home country. </p>
						</div>
						<div>
							<p className="f-underline">Business Transfers</p>
							<p>In the event of a sale, merger, or other transfer of all or substantially all of the assets of Deedly, including as part of a bankruptcy proceeding, we reserve the right to transfer your information to a third party. Such third party’s usage of your information will be in accordance with the terms and conditions of this Privacy Policy.</p>
						</div>
						<h2 className="font30">Protecting Your Personal Information</h2>
						<p>The protection of your personal information is a top priority for Deedly. We implement reasonable and appropriate physical, administrative, and technical safeguards to help protect your information from unauthorized use or disclosure.</p>
						<p>The Deedly website is equipped with HTTPS, meaning that all communications between your browser and the website are encrypted. Additionally, with respect to information collected from registered users of the Services, all data is encrypted both while it is in motion and at rest.</p>
						<p>However, due to the inherently open and somewhat risky nature of the internet, we cannot guarantee, nor do we make any warranty, that your information will be free from unauthorized access or that loss, misuse, destruction, or alteration will not occur.</p>
						<h2 className="font30">Your Rights With Respect To Your Personal Information</h2>
						<p>Users of the Services have the following rights with respect to the personal information collected by Deedly:</p>
						<div className="pad-l20 margin-bottom10">
							<div className="grid-ex-in">
								<p>●</p>
								<p>The right to access, update, or delete the personal information we’ve collected;</p>
								<p>●</p>
								<p>The right to rectify any information that may be inaccurate and/or incomplete;</p>
								<p>●</p>
								<p>The right to provided with a copy of your personal information in a structured, machine-readable, and commonly used format; and</p>
								<p>●</p>
								<p>The right to withdraw your consent at any time, where we relied on such consent to process your personal information.</p>
							</div>
						</div>
						<p>Users can exercise such rights by either sending an email to <a className="a-bl" href="mailto:contact@deedly.com">contact@deedly.com.</a> or by completing the following <a className="a-bl" href="https://educators.deedly.com/ccpa">Web Form.</a></p>
						<h2 className="font30">How Long Does Deedly Keep My Information?</h2>
						<p>We keep your information, including personal information, only as long as necessary to achieve the purposes we’ve detailed in this Privacy Policy. We keep such information for the amount of time required to comply with any legal obligations, to resolve any disputes, and/or enforce our legal agreements and policies.</p>
						<p>In the event that a registered Deedly account (either of an Educator or Student) remains wholly inactive for a period of one (1) year, Deedly shall delete that account and all accompanying information. </p>
						<h2 className="font30">Changes to Privacy Policy</h2>
						<p>Deedly may change or update this Privacy Policy from time to time. In the event of a material change to the policy, Deedly will post the updated policy on our website with an updated date of revision. We encourage you to check this page periodically for any changes. Furthermore, in the event of a material change to our Privacy Policy, all registered users (those with either a Student or Educator account) will be notified and will be required to affirmatively accept and consent to such changes, in order to continue their usage of the Services.</p>
						<h2 className="font30">Please Don’t Hesitate To Reach Out</h2>
						<p>If you have any questions about this Privacy Policy, how we handle personal information, or any other related matters please contact Deedly by sending an email to <a className="a-bl" href="mailto:contact@deedly.com">contact@deedly.com.</a></p>
						<p>As noted above, if you want to exercise your legal rights with respect to your personal information collected by Deedly, please send an email to <a className="a-bl" href="mailto:contact@deedly.com">contact@deedly.com.</a> or by utilizing the <a className="a-bl" href="https://educators.deedly.com/ccpa">Web Form.</a> </p>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrivacyPolicy;
