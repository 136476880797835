import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./container/Home"
import About from "./container/About"
import Donate from "./container/Donate"
import FAQ from "./container/FAQ"
import Deedcoins from "./container/Deedcoins"
import Curriculum from "./container/Curriculum"
import CurDetail from "./container/CurriculumDetail";
import PrivacyPolicy from "./container/privacy-policy";
import Charities from "./container/charities";

function App() {
	return (
		<Router>
			<div className="flex direction-column grows">

				<Header/>

				<div className="flex grows">
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/about" component={About} />
						<Route exact path="/donate" component={Donate} />
						<Route exact path="/faq" component={FAQ} />
						<Route exact path="/privacy-policy" component={PrivacyPolicy}/>
						<Route exact path="/faq/:cat" component={FAQ} />
						<Route exact path="/deedcoins" component={Deedcoins} />
						<Route exact path="/curriculum" component={Curriculum} />
						<Route exact path="/curriculum/:id" component={CurDetail} />
						<Route exact path="/charities" component={Charities}/>
					</Switch>
				</div>

				<Footer/>
			</div>
		</Router>
	);
}

export default App;
